import React, { useState, useEffect } from 'react'
import {
	VStack,
	HStack,
	Spacer,
	Button,
	Icon,
	useToast,
} from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'
import AddWorkTime from '../containers/workhours/addWorkTime'
import AddWorkDays from './../containers/workhours/addWorkDays'
import { useAuthContext } from './../context/auth.context'
import { useArpAssetAlter } from './../hooks/workhours.hooks'
import WorkhoursList from '../containers/workhours/workhoursList'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_WORKHOURS,
//   sectionDetailedTrack,
// } from '../containers/repository/repo.utils'
import { useLanguageContext } from '../context/locale.context'

function Workhours() {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { asset_arp_data } = authData

	const [businessHours, setBusinessHours] = useState([])
	const [businessDays, setBusinessDays] = useState([])
	const [editData, setEditData] = useState(false)
	const [isloading, setIsloading] = useState(true)

	const toast = useToast()

	const { mutate: mutateArpAssetAlter } = useArpAssetAlter()

	useEffect(() => {
		if (asset_arp_data) {
			let assetArpData = JSON.parse(asset_arp_data)
			setBusinessDays(assetArpData.business_days || [])
			setBusinessHours(assetArpData.business_hours || [])
			setIsloading(false)
		}
	}, [asset_arp_data])

	const addBusinessHours = hours => {
		const newHours = [...businessHours, hours]
		updateBusinessHours(newHours)
		setBusinessHours(newHours)
	}

	const removeBusinessHours = (data, type) => {
		if (type === 'time') {
			const newHours = businessHours.filter(item => item !== data)
			updateBusinessHours(newHours)
			setBusinessHours(newHours)
		}
		// else if (type == 'day')
		//   setBusinessDays(businessDays.filter(item => item !== data));
		// setBusinessDays(prevState => prevState.filter(item => item !== data));
	}

	const alterBusinessDays = days => {
		updateBusinessDays(days)
		setBusinessDays(days)
	}

	const alterEditData = () => {
		setEditData(prevState => {
			return !prevState
		})
	}

	const updateBusinessHours = hours => {
		toast({
			title: 'Updating...',
			status: 'info',
			position: 'top',
			duration: 5000,
			isClosable: true,
		})
		let assetArpData = {
			...(authData.asset_arp_data ? JSON.parse(authData.asset_arp_data) : null),
			business_hours: hours,
		}
		let params = {
			asset_arp_data: JSON.stringify(assetArpData),
		}
		mutateArpAssetAlter(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					toast.closeAll()
					toast({
						title: 'Updated Successfully.',
						status: 'success',
						position: 'top',
						duration: 2000,
						isClosable: true,
					})
				}
			},
			onError: async err => {
				toast({
					title: 'Error occured',
					status: 'error',
					position: 'top',
					duration: 1000,
					isClosable: true,
				})
				console.log(err)
			},
		})
	}

	const updateBusinessDays = days => {
		toast({
			title: 'Updating...',
			status: 'info',
			position: 'top',
			duration: 5000,
			isClosable: true,
		})
		let assetArpData = {
			...(authData.asset_arp_data ? JSON.parse(authData.asset_arp_data) : null),
			business_days: days,
		}
		let params = {
			asset_arp_data: JSON.stringify(assetArpData),
		}
		mutateArpAssetAlter(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					toast.closeAll()
					toast({
						title: 'Updated Successfully.',
						status: 'success',
						position: 'top',
						duration: 2000,
						isClosable: true,
					})
				}
			},
			onError: async err => {
				toast({
					title: 'Error occured',
					status: 'error',
					position: 'top',
					duration: 1000,
					isClosable: true,
				})
				console.log(err)
			},
		})
	}

	return (
		<>
			<VStack
				spacing='4'
				alignItems='flex-start'
				w={'100%'}
				h={'calc(100vh - 100px)'}
			>
				<HStack w='100%'>
					<Spacer />
					<Button
						justifySelf='flex-end'
						leftIcon={
							editData ? null : <Icon as={BsPencilSquare} w={5} h={5} />
						}
						variant='solid'
						colorScheme={localStorage.getItem('color')}
						bg={localStorage.getItem('color')}
						borderRadius='sm'
						fontWeight='400'
						boxShadow='md'
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_WORKHOURS,
							//   action: 'Toggle Edit',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Workhours',
							//   buttonName: editData ? 'Cancel' : 'Edit',
							// })
							alterEditData()
						}}
					>
						{editData ? locale['Cancel'] : locale['Edit']}
					</Button>
				</HStack>
				{editData && (
					<>
						<HStack w='100%'>
							<Spacer />
							<AddWorkTime addBusinessHours={addBusinessHours} />
						</HStack>

						<HStack w='100%'>
							<Spacer />
							<AddWorkDays
								businessDays={businessDays}
								alterBusinessDays={alterBusinessDays}
							/>
						</HStack>
					</>
				)}
				<WorkhoursList
					removeList={removeBusinessHours}
					editMode={editData}
					listType='time'
					listData={businessHours}
					headerName='WORKING HOURS LIST'
					loading={isloading}
				/>
				<WorkhoursList
					removeList={removeBusinessHours}
					listType='day'
					listData={businessDays}
					headerName='WORKING DAYS LIST'
					loading={isloading}
				/>
			</VStack>
		</>
	)
}

export default Workhours
