import { lazy } from 'react'

import LoginPage from '../pages/login.page'
import DashboardPage from '../pages/dashboard.page'
import DocumentsPage from '../pages/documents.page'
import FilesPage from '../pages/files.page'
import MeetingsPage from '../pages/meetings.page'
import ChatPage from '../pages/chat.page'
import ReportPage from '../pages/report.page'
import TeamPage from '../pages/team.page'
import TopDownPage from '../pages/TopDown.page'
import ExportPage from '../pages/export.page'
import LeavePage from '../pages/leave.page'
import VidmRepoPage from '../pages/vidmRepo.page'
import Workhours from './../pages/workhours.page'
import EmailFormEntry from '../pages/email-form-entry.page'
import EmailFormPreview from '../pages/email-form-preview/email-form-preview.page'
import GanttChartPage from '../pages/ganttChart.page'
import ARPScores from '../pages/arpscores.page'
import WebLoginPage from '../pages/weblogin.page'
import OriginFormEntry from '../pages/originform-entry.page'
import OriginFormTimeline from '../pages/originform-timeline.page'
import OriginFormPreview from '../pages/originform-preview.page'
import { ProfileDetails } from '../components/navbar/Profile'
import Organization from '../components/navbar/organization'
import WorkStatus from '../components/navbar/WorkStatus'
import Chat from '../components/navbar/Chat/indes'
import GSCore from '../components/navbar/GScore'
import PaymentPage from '../pages/payment.page'
import ExportData from '../pages/export-data/export-data.page'
import EmailReply from '../pages/email-reply'
import MobileDashboardPage from '../pages/mobile-dashboard.page'
import AzureSendMail from '../pages/azure-send-mail'

// const LoginPage = lazy(() => import('../pages/login.page'));
// const DashboardPage = lazy(() => import('../pages/dashboard.page'));
// const DocumentsPage = lazy(() => import('../pages/documents.page'));
// const FilesPage = lazy(() => import('../pages/files.page'));
// const MeetingsPage = lazy(() => import('../pages/meetings.page'));
// const ChatPage = lazy(() => import('../pages/chat.page'));
// const ReportPage = lazy(() => import('../pages/report.page'));
// const TeamPage = lazy(() => import('../pages/team.page'));
// const ExportPage = lazy(() => import('../pages/export.page'));
// const LeavePage = lazy(() => import('../pages/leave.page'));
// const VidmRepoPage = lazy(() => import('../pages/vidmRepo.page'));

const RoutesConfig = [
	{
		path: '/login',
		exact: true,
		restricted: false,
		component: LoginPage,
	},
	{
		path: '/login/:params',
		exact: true,
		restricted: false,
		component: LoginPage,
	},
	{
		path: '/forms/entry/:params',
		exact: true,
		restricted: false,
		is_external: true,
		component: EmailFormEntry,
	},
	{
		path: '/forms/preview/:params',
		exact: true,
		restricted: false,
		is_external: true,
		component: EmailFormPreview,
	},
	{
		path: '/',
		exact: true,
		restricted: true,
		component: FilesPage,
	},
	{
		path: '/files',
		exact: true,
		restricted: true,
		component: FilesPage,
	},
	{
		path: '/meetings',
		exact: true,
		restricted: true,
		component: MeetingsPage,
	},
	{
		path: '/documents',
		exact: true,
		restricted: true,
		component: DocumentsPage,
	},
	{
		path: '/dashboard',
		exact: true,
		restricted: true,
		component: DashboardPage,
	},
	{
		path: '/dashboard/:params',
		exact: true,
		restricted: true,
		component: DashboardPage,
	},
	{
		path: '/chat',
		exact: true,
		restricted: true,
		component: ChatPage,
	},
	{
		path: '/report',
		exact: true,
		restricted: true,
		component: ReportPage,
	},
	{
		path: '/team',
		exact: true,
		restricted: true,
		component: TeamPage,
	},
	{
		path: '/organizations',
		exact: true,
		restricted: true,
		component: Organization,
	},
	{
		path: '/workstatus',
		exact: true,
		restricted: true,
		component: WorkStatus,
	},
	{
		path: '/chats',
		exact: true,
		restricted: true,
		component: Chat,
	},
	{
		path: '/gscore',
		exact: true,
		restricted: true,
		component: GSCore,
	},
	{
		path: '/profile',
		exact: true,
		restricted: true,
		component: ProfileDetails,
	},
	{
		path: '/export',
		exact: true,
		restricted: true,
		component: ExportPage,
	},
	{
		path: '/leave',
		exact: true,
		restricted: true,
		component: LeavePage,
	},
	{
		path: '/workhours',
		exact: true,
		restricted: true,
		component: Workhours,
	},
	{
		path: '/arpscores',
		exact: true,
		restricted: true,
		component: ARPScores,
	},
	{
		path: '/vidm',
		exact: true,
		restricted: true,
		component: VidmRepoPage,
	},
	{
		path: '/ganttChart',
		exact: true,
		restricted: true,
		component: GanttChartPage,
	},
	{
		path: '/weblink/:params',
		exact: true,
		restricted: false,
		component: WebLoginPage,
	},
	{
		path: '/originform/entry/:params',
		exact: true,
		restricted: false,
		is_originform: true,
		component: OriginFormEntry,
	},
	{
		path: '/originform/timeline/:params',
		exact: true,
		restricted: false,
		is_originform: true,
		component: OriginFormTimeline,
	},
	{
		path: '/originform/formpreview/:params',
		exact: true,
		restricted: false,
		is_originform: true,
		component: OriginFormPreview,
	},
	{
		path: '/payment-response/:params',
		exact: true,
		restricted: true,
		component: PaymentPage,
	},
	{
		path: '/forms/exportview/:params',
		exact: true,
		restricted: false,
		is_export: true,
		component: ExportData,
	},
	{
		path: '/email_link/:params',
		exact: true,
		restricted: false,
		is_external: true,
		component: EmailReply,
	},
	{
		path: '/mobile-dashboard/:params',
		exact: true,
		restricted: false,
		is_mobile_dashboard: true,
		component: MobileDashboardPage,
	},
	{
		path: '/azure-mail',
		exact: true,
		restricted: true,
		component:AzureSendMail
	},
	/* Like way add new pages into the pages folder and extend the routes. */
]

export default RoutesConfig
