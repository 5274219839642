import React, { createContext, useState, useContext } from 'react';
import { Route } from 'react-router-dom';
import EmailLayout from '../../layouts/email.layouts';

const EmailContext = createContext();
export const useEmailContext = () => useContext(EmailContext);

const EmailRoute = props => {
  const [orgId, setOrgId] = useState(0);
  const [formName, setFormName] = useState('');
  return (
    <EmailContext.Provider value={{ formName, setFormName, orgId, setOrgId }}>
      <EmailLayout orgId={orgId} formName={formName}>
        <Route {...props} />
      </EmailLayout>
    </EmailContext.Provider>
  );
};

export default EmailRoute;
