/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import PrivateRoute from './private/private.route'
import PublicRoute from './public/public.route'
import EmailRoute from './email/email.route'
import WebLinkRoute from './weblink/web.route'
import ExportRoute from './export/export.route'
import DashboardRoute from './dashboard/dashboard.route'

import RoutesConfig from './route.config'
import { RouteFallback } from './route.fallback'
import { useAuthContext } from '../context/auth.context'
import { loginConst } from '../utils/action.constant'
// import ReactGA from 'react-ga'

const NotFound = () => {
	return (
		<div className='not-found'>
			<h4>Page Not Found</h4>
		</div>
	)
}

const Routes = props => {
	const { dispatch } = useAuthContext()

	const history = useHistory()

	useEffect(() => {
		// Track Analytics change in URL
		// history.listen(location => {
		// 	ReactGA.set({ page: location.pathname })
		// 	ReactGA.pageview(location.pathname)
		// })
		/*return () => {
			dispatch({ type: loginConst.LOGOUT })
			localStorage.clear()
		}*/
	}, [])
	// React.useEffect(() => {
	//   return () => {
	//     dispatch({ type: loginConst.LOGOUT });
	//   };
	// }, [props]);
	return (
		<Switch>
			{(RoutesConfig || []).map(routeProps => {
				if (routeProps.restricted) {
					return <PrivateRoute key={routeProps.path} {...routeProps} />
				} else if (!routeProps.restricted && routeProps.is_originform) {
					return <WebLinkRoute key={routeProps.path} {...routeProps} />
				} else if (!routeProps.restricted && routeProps.is_external) {
					return <EmailRoute key={routeProps.path} {...routeProps} />
				} else if (!routeProps.restricted && routeProps.is_export) {
					return <ExportRoute key={routeProps.path} {...routeProps} />
				} else if (!routeProps.restricted && routeProps.is_mobile_dashboard) {
					return <DashboardRoute key={routeProps.path} {...routeProps} />
				} else {
					return <PublicRoute key={routeProps.path} {...routeProps} />
				}
			})}
			<Route component={NotFound} />
		</Switch>
	)
}

export default Routes
