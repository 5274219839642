import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';

const PublicRoute = props => {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  if (!!token && token !== 'undefined') {
    // return <Redirect to="/" />;
  }

  return (
    <>
      <Route {...props} />
    </>
  );
};

export default PublicRoute;
