import React, { useState } from 'react'
import {
  VStack,
  Box,
  Center,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
// import * as utils from '../utils/common.util'
// import { MdDescription } from 'react-icons/md';
import Header from '../containers/vidm/vidmHeader'
import BcTable from '../containers/vidm/vidmTable'
import { useLanguageContext } from '../context/locale.context'
import { useGetVidmDocs } from '../hooks/vidm.hooks'
import FileExplorerModal from '../containers/vidm/fileExplorer'
import CommentsTimeLine from '../containers/vidm/commentTimeLine'
import { sectionDetailedTrack } from '../containers/repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../containers/repository/repo.utils'

export const VidmPage = props => {
  const { mutate, isLoading } = useGetVidmDocs()
  const {
    state: { locale },
  } = useLanguageContext()
  const SearchFilterList = [
    {
      label: locale['Account Code'],
      key: 'account',
      value: 1,
    },
    {
      label: locale['Customer Name'],
      key: 'customer',
      value: 2,
    },
  ]
  const [selectedFilter, setSelectedFilter] = useState(SearchFilterList[0])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenComment,
    onOpen: onOpenComment,
    onClose: onCloseComment,
  } = useDisclosure()
  const [tableData, setTableData] = useState([])
  const [query, setQuery] = useState('')
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState('')
  const openFileExplorer = document => {
    document.folders = JSON.parse(document.file_path).filepath
    setSelectedDocument(document)
    onOpen()
  }
  const openCommentExplorer = document => {
    setSelectedDocument(document)
    onOpenComment(true)
  }
  const loadVidmDocs = (text, pageNo = 0) => {
    mutate(
      {
        page_no: pageNo,
        page_size: 100,
        search_string: text ? text : query,
        from_date: '',
        to_date: '',
        flag: selectedFilter.value,
      },
      {
        onSuccess: async res => {
          const {
            data: { status, response },
          } = res
          if (status === 200) {
            if (response.length > 0) {
              setIsLoadMore(true)
            } else {
              setIsLoadMore(false)
            }
            if (pageNo > 0) {
              setTableData([...tableData, ...response])
            } else {
              setTableData(response)
            }
          }
        },
        onError: async err => {
          console.log(err)
        },
      }
    )
  }
  return (
    <>
      <VStack spacing={2} w="full" h={'calc(100vh - 170px)'}>
        <Header
          selected={selectedFilter}
          onChange={setSelectedFilter}
          onSearch={value => {
            setQuery(value)
            loadVidmDocs(value)
          }}
          filterOptions={SearchFilterList}
        />
        {!!query && !!selectedFilter ? (
          <Box w="full" m={2}>
            <Text>Showing results in {selectedFilter.label}</Text>
          </Box>
        ) : null}
        <BcTable
          vidmList={tableData ? tableData : []}
          openFileExplorer={openFileExplorer}
          isLoading={isLoading}
          openCommentExplorer={openCommentExplorer}
        />
        {tableData && tableData.length >= 100 && isLoadMore && (
          <Box>
            <Center>
              <Button
                bg={localStorage.getItem('color')}
                color="white"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_VIDM,
                    action: 'Load More',
                  })
                  loadVidmDocs('', tableData.length)
                }}
              >
                {locale['Load More']}
              </Button>
            </Center>
          </Box>
        )}
      </VStack>
      {isOpen && (
        <FileExplorerModal
          onOpen={isOpen}
          onClose={onClose}
          document={selectedDocument}
        />
      )}
      {
        isOpenComment && (
          <CommentsTimeLine
            document={selectedDocument}
            onOpen={isOpenComment}
            onClose={onCloseComment}
          />
        )

        // <CommentsExplorerModal
        //   comments={selectedDocument}
        //   onOpen={isOpenComment}
        //   onClose={onCloseComment}
        // />
      }
    </>
  )
}

export default VidmPage
