/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { VStack, Text, Flex } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import TeamHeader from '../containers/team-panel/teamHeader'
import TeamList from '../containers/team-panel/teamList'
import { useGetTeamMembers } from '../hooks/team.hooks'
import { dashboardConst } from '../utils/action.constant'
import { useDashboardContext } from '../context/dashboard.context'
import { DashboardContextProvider } from '../context/dashboard.context'
import { useAuthContext } from '../context/auth.context'
import {
  GA_CATEGORY_TEAM,
  sectionDetailedTrack,
} from '../containers/repository/repo.utils'
import { useLanguageContext } from '../context/locale.context'

function TeamPage() {
  const { mutate, isLoading } = useGetTeamMembers()
  const {
    state: { locale },
  } = useLanguageContext()

  const TeamFilterList = [
    {
      label: locale['Direct'],
      key: 'direct',
      value: 1,
    },
    {
      label: locale['Indirect'],
      key: 'Indirect',
      value: 2,
    },
  ]
  const [query, setquery] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(TeamFilterList[0])
  const [reporteesList, setReporteesList] = useState([])
  const {
    state: { sipReporteeList },
    dispatch: dashboardDispatch,
  } = useDashboardContext()
  const {
    state: { authData },
  } = useAuthContext()

  useEffect(() => {
    console.log(authData);
    // if (authData.asset_email_id) {
    let reportee = { ...authData }
    loadTeams(reportee)
    // }
  }, [selectedFilter, authData && authData.asset_email_id])

  const reloadTeams = useCallback(
    reportee => {
      setReporteesList([])
      const onSuccess = {
        onSuccess: data => {
          setReporteesList(data)
        },
      }
      if (selectedFilter.value === 1) {
        mutate(
          {
            page_start: 0,
            page_limit: 500,
            start_from: 0,
            limit_value: 500,
            flag: selectedFilter.value,
            user_asset_id: reportee.manager_asset_id,
          },
          onSuccess
        )
      } else {
        mutate(
          {
            page_start: 0,
            page_limit: 500,
            start_from: 0,
            limit_value: 500,
            flag: selectedFilter.value,
            user_asset_id: authData.asset_id,
          },
          onSuccess
        )
      }
    },
    [selectedFilter]
  )

  const loadTeams = useCallback(
    reportee => {
      setReporteesList([])
      const onSuccess = {
        onSuccess: data => {
          addReporteeToStack(reportee)
          setReporteesList(data)
        },
      }
      mutate(
        {
          page_start: 0,
          page_limit: 500,
          start_from: 0,
          limit_value: 500,
          flag: selectedFilter.value,
          user_asset_id: reportee.asset_id,
        },
        onSuccess
      )
    },
    [selectedFilter]
  )

  const addReporteeToStack = (reportee, isManager) => {
    dashboardDispatch({
      type: isManager
        ? dashboardConst.ADD_SIP_RESOURCE
        : dashboardConst.SET_SIP_RESOURCE,
      reportee: reportee,
    })
  }

  return (
    <>
      <VStack spacing={2} w="full" h={'calc(100vh - 100px)'}>
        <TeamHeader
          selected={selectedFilter}
          onChange={setSelectedFilter}
          onSearch={setquery}
          filterOptions={TeamFilterList}
          users={reporteesList}
        />
        <Flex mb={3} p={3} w="100%" alignItems="left">
          {!!sipReporteeList &&
            sipReporteeList.map((item, i) => (
              <>
                {i !== 0 ? '/' : null}
                <Text
                  fontWeight="bold"
                  color={localStorage.getItem('color')}
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_TEAM,
                      action: 'Load Teams',
                    })
                    loadTeams(item)
                  }}
                >
                  {' '}
                  {item.operating_asset_id === 0
                    ? item.previous_operating_asset_first_name
                    : item.operating_asset_first_name}{' '}
                </Text>
              </>
            ))}
        </Flex>
        <TeamList
          users={reporteesList}
          query={query}
          selected={selectedFilter}
          filterOptions={TeamFilterList}
          reload={reloadTeams}
          isLoading={isLoading}
          loadTeams={loadTeams}
        />
      </VStack>
    </>
  )
}

export default () => {
  return (
    <DashboardContextProvider>
      <TeamPage />
    </DashboardContextProvider>
  )
}
