import React, { createContext, useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import ExportLayout from '../../layouts/export.layouts'

const ExportContext = createContext()
export const useExportContext = () => useContext(ExportContext)

const ExportRoute = props => {
  const [orgId, setOrgId] = useState(0)
  const [formName, setFormName] = useState('')
  return (
    <ExportContext.Provider value={{ formName, setFormName, orgId, setOrgId }}>
      <ExportLayout orgId={orgId} formName={formName}>
        <Route {...props} />
      </ExportLayout>
    </ExportContext.Provider>
  )
}

export default ExportRoute
