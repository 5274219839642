import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom'
import PrivateLayout from '../../layouts/private.layouts'
import { configureAmplify } from '../../utils/auth.util'
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant'
const PrivateRoute = props => {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY)
  if (!token || token === 'undefined') {
    return <Redirect to="/login" />
  }

  configureAmplify()

  return (
    <>
      <PrivateLayout>
        <Route {...props} />
      </PrivateLayout>
    </>
  )
}

export default PrivateRoute
