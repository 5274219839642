import React, { createContext, useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import WebLinkLayout from '../../layouts/weblink.layouts'

const WebLinkContext = createContext()
export const useWebLinkContext = () => useContext(WebLinkContext)

const WebLinkRoute = props => {
  const [orgId, setOrgId] = useState(0)
  const [formName, setFormName] = useState('')
  return (
    <WebLinkContext.Provider value={{ formName, setFormName, orgId, setOrgId }}>
      <WebLinkLayout orgId={orgId} formName={formName}>
        <Route {...props} />
      </WebLinkLayout>
    </WebLinkContext.Provider>
  )
}

export default WebLinkRoute
