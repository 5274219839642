import React, { createContext, useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import DashboardLayout from '../../layouts/dashboard.layouts'

const DashboardContext = createContext()
export const useDashboardContext = () => useContext(DashboardContext)

const DashboardRoute = props => {
  const [orgId, setOrgId] = useState(0)
  return (
    <DashboardContext.Provider value={{ orgId, setOrgId }}>
      <DashboardLayout orgId={orgId}>
        <Route {...props} />
      </DashboardLayout>
    </DashboardContext.Provider>
  )
}

export default DashboardRoute
