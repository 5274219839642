/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { OTPLogin } from '../components/login/webotp-login'
import PublicLayout from '../layouts/public.layouts'
import { useParams } from 'react-router-dom'

export default function WebLoginPage(props) {
  const urlParams = useParams()


  useEffect(() => {
    const apps = {
      Android: 'https://play.google.com/store/apps/details?id=co.desker.service',
      iOS: 'itms-apps://itunes.apple.com/app/1454812732',
    };
    let deviceDetect = getMobileOperatingSystem();
    if (deviceDetect === 'iOS') {
      window.location.replace(apps[deviceDetect]);
    } else if (deviceDetect === 'Android') {
      window.location.replace(apps[deviceDetect]);
    }
  }, [])

  function getMobileOperatingSystem() {
    let OSName = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") !== -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") !== -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") !== -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") !== -1) OSName = "iOS";

    return OSName;

  }

  return (
    <PublicLayout urlParams={urlParams}>
      <OTPLogin urlParams={urlParams} />
    </PublicLayout>
  )
}
